import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  selectedInvoiceData,
  setInvoiceData
} from 'store/slices/selectedDataSlice';
import { validationErrors } from 'store/slices/validationSlice';

import { InvoiceData, Packages } from 'types/bundle';
import { RootState } from 'store/store';

import { Row, Col, Radio, RadioChangeEvent } from 'antd';

import FloatingLabelInput from 'components/FloatingLabelInput';
import FloatingLabelSelect from 'components/FloatingLabelSelect';

import style from './style.module.css';
import { selectedCurrentPackage } from 'store/slices/categoriesSlice';
import { isNumber } from 'utils/Utils';

const InvoiceDetails = () => {
  const dispatch = useDispatch();

  const invoiceInfo: InvoiceData = useSelector(selectedInvoiceData);

  const errors = useSelector(validationErrors);

  const isEmailRequired = useSelector(
    (state: RootState) => state.validationErrors.emailRequired
  );

  const currentPackage: Packages = useSelector(selectedCurrentPackage);

  const [selectedOption, setSelectedOption] = useState<boolean>(
    invoiceInfo.equalToCustomer
  );

  const [userData, setUserData] = useState<InvoiceData>(invoiceInfo);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === 'email') {
      setUserData({
        ...userData,
        email: value.replace(/\s+/g, '')
      });

      dispatch(
        setInvoiceData({
          ...invoiceInfo,
          email: value.replace(/\s+/g, '')
        })
      );
    } else {
      setUserData({
        ...userData,
        [name]: value
      });

      dispatch(
        setInvoiceData({
          ...invoiceInfo,
          [name]: value
        })
      );
    }
  };

  const onTitleChange = (event: string) => {
    setUserData({ ...userData, title: event });

    dispatch(setInvoiceData({ ...invoiceInfo, title: event }));
  };

  const onOwnerChange = (event: RadioChangeEvent) => {
    setSelectedOption(event.target.value);

    dispatch(
      setInvoiceData({
        ...invoiceInfo,
        equalToCustomer: event.target.value
      })
    );
  };

  return (
    <div className={style.invoice_details_container}>
      <Radio.Group
        value={selectedOption}
        onChange={onOwnerChange}
        className="margin_bottom_l"
      >
        <Row justify={'start'} gutter={[0, 24]} className={style.radio_wrapper}>
          <Col span={24} xl={6}>
            <Radio value={true}>Auftraggeber</Radio>
          </Col>
          <Col span={24} xl={12}>
            <Radio value={false}>Sonstige (bitte angeben)</Radio>
          </Col>
        </Row>
      </Radio.Group>

      {!selectedOption && (
        <>
          {currentPackage?.customer_group_firm && (
            <Row className={`margin_bottom_m ${style.no_margin}`}>
              <Col span={24}>
                <FloatingLabelInput
                  label="Firma*"
                  value={userData?.company}
                  onChangeHandler={onChangeHandler}
                  name="company"
                  errorStatus={
                    errors?.invoiceData?.company && userData.company === ''
                      ? 'error'
                      : ''
                  }
                  errorMessage={
                    errors?.invoiceData?.company && userData.company === ''
                      ? errors?.invoiceData?.company
                      : undefined
                  }
                />
              </Col>
            </Row>
          )}
          <Row gutter={12} className={`margin_bottom_m ${style.no_margin}`}>
            <Col span={8} md={4}>
              <FloatingLabelSelect
                label="Anrede*"
                value={userData.title}
                options={[
                  { label: 'Herr', value: 'Herr' },
                  { label: 'Frau', value: 'Frau' }
                ]}
                onChangeHandler={onTitleChange}
                errorStatus={
                  errors?.invoiceData?.title && userData.title === ''
                    ? 'error'
                    : ''
                }
                errorMessage={
                  errors?.invoiceData?.title && userData.title === ''
                    ? errors?.invoiceData?.title
                    : undefined
                }
              />
            </Col>
            <Col span={16} md={10}>
              <FloatingLabelInput
                label="Vorname*"
                value={userData.first_name}
                onChangeHandler={onChangeHandler}
                name="first_name"
                errorStatus={
                  errors?.invoiceData?.first_name && userData.first_name === ''
                    ? 'error'
                    : ''
                }
                errorMessage={
                  errors?.invoiceData?.first_name && userData.first_name === ''
                    ? errors?.invoiceData?.first_name
                    : null
                }
              />
            </Col>
            <Col span={24} md={10}>
              <FloatingLabelInput
                label="Nachname*"
                value={userData.last_name}
                onChangeHandler={onChangeHandler}
                name="last_name"
                errorStatus={
                  errors?.invoiceData?.last_name && userData.last_name === ''
                    ? 'error'
                    : ''
                }
                errorMessage={
                  errors?.invoiceData?.last_name && userData.last_name === ''
                    ? errors?.invoiceData?.last_name
                    : null
                }
              />
            </Col>
          </Row>

          <Row gutter={12} className={`margin_bottom_m ${style.no_margin}`}>
            <Col span={24}>
              <FloatingLabelInput
                label={`E-Mail${isEmailRequired ? '*' : ''}`}
                value={userData.email}
                onChangeHandler={onChangeHandler}
                name="email"
                errorStatus={
                  errors?.invoiceData?.email && userData.email === ''
                    ? 'error'
                    : errors?.invoiceData?.email && userData.email !== ''
                      ? 'error'
                      : ''
                }
                errorMessage={
                  errors?.invoiceData?.email && userData.email === ''
                    ? errors?.invoiceData?.email
                    : errors?.invoiceData?.email && userData.email !== ''
                      ? errors?.invoiceData?.email
                      : null
                }
              />
            </Col>
          </Row>

          <Row gutter={12} className={`margin_bottom_m ${style.no_margin}`}>
            <Col span={16} md={12}>
              <FloatingLabelInput
                label="Straße*"
                value={userData.street}
                onChangeHandler={onChangeHandler}
                name="street"
                errorStatus={
                  errors?.invoiceData?.street && userData.street === ''
                    ? 'error'
                    : ''
                }
                errorMessage={
                  errors?.invoiceData?.street && userData.street === ''
                    ? errors?.invoiceData?.street
                    : null
                }
              />
            </Col>
            <Col span={8} md={6}>
              <FloatingLabelInput
                label="Hausnummer*"
                value={userData.house_number}
                onChangeHandler={onChangeHandler}
                name="house_number"
                errorStatus={
                  (errors?.invoiceData?.house_number &&
                    userData.house_number === '') ||
                  (userData?.house_number && !isNumber(userData?.house_number))
                    ? 'error'
                    : ''
                }
                errorMessage={
                  (errors?.invoiceData?.house_number &&
                    userData.house_number === '') ||
                  !isNumber(userData?.house_number)
                    ? (errors?.invoiceData?.house_number as string)
                    : null
                }
              />
            </Col>
            <Col span={24} md={6}>
              <FloatingLabelInput
                label="Hausnummerzusatz (optional)"
                value={userData.house_suffix}
                onChangeHandler={onChangeHandler}
                name="house_suffix"
              />
            </Col>
          </Row>

          <Row gutter={12} className="margin_bottom_m">
            <Col span={10} md={12}>
              <FloatingLabelInput
                label="Postleitzahl*"
                value={userData.post_code}
                onChangeHandler={onChangeHandler}
                name="post_code"
                errorStatus={
                  (errors?.invoiceData?.post_code &&
                    userData.post_code === '') ||
                  !isNumber(userData?.post_code) ||
                  (isNumber(userData?.post_code) &&
                    userData?.post_code &&
                    userData?.post_code?.length !== 5)
                    ? 'error'
                    : ''
                }
                errorMessage={
                  (errors?.invoiceData?.post_code &&
                    userData.post_code === '') ||
                  !isNumber(userData?.post_code) ||
                  (isNumber(userData?.post_code) &&
                    userData?.post_code?.length !== 5)
                    ? errors?.invoiceData?.post_code
                    : null
                }
              />
            </Col>
            <Col span={14} md={12}>
              <FloatingLabelInput
                label="Ort*"
                value={userData.location}
                onChangeHandler={onChangeHandler}
                name="location"
                errorStatus={
                  errors?.invoiceData?.location && userData.location === ''
                    ? 'error'
                    : ''
                }
                errorMessage={
                  errors?.invoiceData?.location && userData.location === ''
                    ? errors?.invoiceData?.location
                    : null
                }
              />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default InvoiceDetails;
