import * as Yup from 'yup';

const REQUIRED_MESSAGE = 'Erforderlich';
const INVALID_NUMBER_MESSAGE = 'Nur Zahlen.';

export interface ExistingConnectionsValidationData {
  previousProvider: string;
  currentConnectionOwnerEqualToCustomer: boolean;
  currentConnectionOwner: CurrentConnectionOwnerValidationData;
  existingPhoneNumbers: ExistingPhoneNumberValidationData;
}

export interface CurrentConnectionOwnerValidationData {
  title?: string;
  name?: string;
  lastName?: string;
  company?: string;
}

export interface ExistingPhoneNumberValidationData {
  areaCode: string;
  phoneNumber: string;
}

const currentConnectionOwnerSchema: Yup.Schema<CurrentConnectionOwnerValidationData> =
  Yup.object().shape({
    company: Yup.string().test(
      'companyOrTitleNameLastName',
      REQUIRED_MESSAGE,
      function (value) {
        const { title, name, lastName } = this.parent;
        if (!value && !(title && name && lastName)) {
          return false;
        }
        return true;
      }
    ),
    title: Yup.string().test(
      'companyOrTitleNameLastName',
      REQUIRED_MESSAGE,
      function (value) {
        const { company } = this.parent;
        if (!value && !company) {
          return false;
        }
        return true;
      }
    ),
    name: Yup.string().test(
      'companyOrTitleNameLastName',
      REQUIRED_MESSAGE,
      function (value) {
        const { company } = this.parent;
        if (!value && !company) {
          return false;
        }
        return true;
      }
    ),
    lastName: Yup.string().test(
      'companyOrTitleNameLastName',
      REQUIRED_MESSAGE,
      function (value) {
        const { company } = this.parent;
        if (!value && !company) {
          return false;
        }
        return true;
      }
    )
  });

const existingPhoneNumberSchema: Yup.Schema = Yup.array(
  Yup.object().shape({
    areaCode: Yup.string()
      .required(REQUIRED_MESSAGE)
      .matches(/^(?:[0-9]\d*)?$/, INVALID_NUMBER_MESSAGE),
    phoneNumber: Yup.string()
      .required(REQUIRED_MESSAGE)
      .matches(/^(?:[0-9]\d*)?$/, INVALID_NUMBER_MESSAGE)
  })
);

const validationSchema = Yup.object({
  previousProvider: Yup.string().required(REQUIRED_MESSAGE),
  currentConnectionOwnerEqualToCustomer: Yup.boolean(),
  currentConnectionOwner: Yup.object().when(
    'currentConnectionOwnerEqualToCustomer',
    {
      is: false,
      then: () => currentConnectionOwnerSchema,
      otherwise: () => currentConnectionOwnerSchema.strip()
    }
  ),
  existingPhoneNumbers: existingPhoneNumberSchema
});

export default validationSchema;
